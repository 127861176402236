<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" order-sm="1">
				<v-subheader>Nuevo escandallo</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevoEscandallo
          @reload="$emit('reload')"
          :key="keyHelper"
          :value="escandallo"
          :familias="familias"
          :articulos="articulos"
        />
      </v-col>
      <v-col cols="12" md="8" order-sm="1">
        <v-subheader>Listado escandallos</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="escandallos.filter(e => !loading)"
          :headers="escandallosHeader"
          class="fixed-checkbox"
          item-key="idEscandallo"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="escandallos"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item.n="{ item, index }">
            <td style="white-space: nowrap !important;padding-left: 22px !important;">{{ index+1 }}</td>
          </template>
          <template v-slot:item.familiasShow="{ item }">
            <td style="white-space: nowrap !important;padding-left: 22px !important;" :title="item.familiasShow">
              {{ item.familiasShow }}
            </td>
          </template>
          <template v-slot:item.precio="{ item }">
            {{ item.formula ? pr(item).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '' }}
          </template>
          <template v-slot:item.nombre="{ item }">
            {{ item.nombre }}
          </template>
          <template v-slot:item.idArticulo="{ item }">
            {{ (articulos.filter(fam => fam.idArticulo == item.idArticulo)[0].nombre || '') }}
          </template>
          <template v-slot:item.actualizado="{ item }">
            {{ item.actualizado.toLocaleDateString('esp', { day : 'numeric', month : 'short', year : 'numeric' }).toLocaleUpperCase() }}
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click="escandallo = item"
                small
                rounded
                color="secondary"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click="deleteEscandallo(item)"
                small
                class="ml-1"
                rounded
                color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
		
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  props: {
    loading: Boolean,
    familias: Array,
    articulos: Array,
    escandallos: Array,
  },
  components: {
    NuevoEscandallo: () => import("./NuevoEscandallo.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      escandallo: null,
      inlineFilters: {},
      keyHelper: 0,
      escandallosHeader: [
        { text: "Nº", value: "n", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Artículo", value: "idArticulo", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Escandallo", value: "nombre", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Familia", value: "familiasShow", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Precio", value: "precio", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Fórmula", value: "formula", cellClass: "pa-1 text-no-wrap font-weight-bold text-center" },
        { text: "Nota", value: "nota", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Actualizado", value: "actualizado", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    escandallo() {
      this.keyHelper++;
    },
  },
  methods: {
    pr(i){
      return eval(i.formula.replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == i.idArticulo)[0].precio))
    },
    parseDate,
    async deleteEscandallo({ idEscandallo, nombre }) {
			const confirmation = await this.$refs.confirm.open(
				`Escandallo: ${nombre}`,
        `ATENCIÓN: Todas las recetas asociados a este escandallo se elminarán, junto a los menús que contengan esas recetas. ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar el escandallo?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/escandallos/" + idEscandallo,
      });
      this.$emit('reload')
    }
  }
};
</script>
